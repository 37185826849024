import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../settings';
const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const volumecontrol = `${settings.IMAGES_BASE_URL}/images/assets/bmw-volume.png`;


class VideoBackground extends React.Component {
  state = {
    width: '',
    volume: false
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    })
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  scrollToRef = () => {
    window.scrollTo({
      top: 1000, 
      left: 0, 
      behavior: 'smooth' 
    });
  };

  volumeOn = () => {
    this.setState({
      volume: !this.state.volume
    })
  }

  render() {
    const { width } = this.state;
    const isMobile = width < 767;
    const showHeader = (!isMobile || (isMobile && !this.props.hideMobileHeader));

    return (
      <div>
        {showHeader ?
          <div className="hero__header video-background-header">
            {this.props.topLeftChildren && (
                <div className="hero__header_topleft">
                  {this.props.topLeftChildren}
                </div>
            )}
          </div> : ""
        }
        {isMobile ? (
          <LazyLoadImage effect="blur" src={this.props.poster} alt={this.props.imageAlt ? this.props.imageAlt : "Video Poster"} />
        ) : (
          <div className="video-background">
            <video
              playsInline="playsinline"
              autoPlay="autoplay"
              muted={this.state.volume ? "" : "muted"}
              loop="loop"
              poster={this.props.poster}
            >
              <source src={this.props.url} type="video/mp4" />
              <track type="text/vtt" src={this.props.captions} label="English" kind="captions" default />
            </video>
            <div className="video-background__content">
              <h1 className="video-background__header-text" aria-label='video-title'>{this.props.headerTitle}</h1>
              <button className="arrow" aria-label='arrow-btn' onClick={this.props.onArrowClick || this.scrollToRef} onKeyDown={this.scrollToRef}>
                <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
              </button>
              {!this.props.noAudio && <button className="volume__control" onClick={this.volumeOn}><img src={volumecontrol} alt="Volume on" /> </button>}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default VideoBackground;
