import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="next-month-button" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
      </svg>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-month-button" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
      </svg>
    </div>
  );
}
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const monthsWithNoYear = [
  {
    id: 1,
    name: "January",
    month: "01",
    year: 2024,
  },
  {
    id: 2,
    name: "February",
    month: "02",
    year: 2024
  },
  {
    id: 3,
    name: "March",
    month: "03",
    year: 2024,
  },
  {
    id: 4,
    name: "April",
    month: "04",
    year: 2024
   },
  {
    id: 5,
    name: "May",
    month: "05",
    year: 2024,
  },
  {
    id: 6,
    name: "June",
    month: "06",
    year: 2024
  },
  {
    id: 7,
    name: "July",
    month: "07",
    year: 2024,
  },
  {
    id: 8,
    name: "August",
    month: "08",
    year: 2024
  },
  {
    id: 9,
    name: "September",
    month: "09",
    year: 2024,
  },
  {
    id: 10,
    name: "October",
    month: "10",
    year: 2024
  },
  {
    id: 11,
    name: "November",
    month: "11",
    year: 2024,
  },
  {
    id: 12,
    name: "December",
    month: "12",
    year: currentYear
  },
];
const Months = monthsWithNoYear.map(function (obj) { parseInt(obj.month) >= currentMonth ? obj.year = currentYear : obj.year = currentYear + 1; return obj})
const MonthSlider = (props) => {
  const settings = {
    className: "months-slider",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    initialSlide: props.month || new Date().getMonth(),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 
  const itemsToShow = props.showSpecificMonths === true ? (Months.filter(function(item){
    if (props.monthsToShow.includes(item.month)) return item})) : Months;
 
  return (
    <>
      <p className="month-slider-header">{props.monthSliderHeader}</p>
      <div
        className="months-slider-wrapper"
        style={{
          backgroundColor: `${props.backgroundColor}`,
        }}
      >
        <Slider {...settings}>
          {itemsToShow.map((item) => {
            return (
              <div key={item.id}>
                <a
                  href={`https://pds.eventsbmw.com/?brand=${props.brand || 'BMW'}&date=${item.year}-${item.month}-01${props.urlQuery}`}
                  target="_blank"
                  tabIndex="-1"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: "4px",
                    }}
                  >
                    {item.name}{" "}
                  </span>
                  <span>{item.year}</span>
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};
export default MonthSlider;
