import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import settings from "../../../settings";
import useWindowSize from "../../hooks/useWindowSize";

const banner = `${settings.IMAGES_BASE_URL}/images/m4-gt4/location-banner-cali.jpg`;

const ThermalCaliforniaBanner = () => {
    const {isMobile, isDesktop} = useWindowSize();

    return (
        <>
            {isMobile && (
                <section className="proinstructors__mobile">
                    <LazyLoadImage
                        effect="blur"
                        src={banner}
                        alt="Pro Instructors Banner"
                    />
                    <div className="container">
                        <div className="proinstructors__content">
                            <h3>Sunshine and BMW racing cars</h3>
                            <p>
                                Set against the backdrop of a beautiful mountain range, The Thermal Club is an exclusive
                                track that offers three unique layouts, plus our own challenge course to encourage
                                maximum thrills.
                            </p>
                        </div>
                    </div>
                </section>
            )}
            {isDesktop && (
                <section
                    className="locations__banner"
                    style={{
                        backgroundImage: `url(${banner})`,
                    }}
                >
                    <div className="container">
                        <div className="locations__banner__content">
                            <h3>Sunshine and BMW racing cars</h3>
                            <h4>Thermal, California</h4>
                            <p>
                                Set against the backdrop of a beautiful mountain range, The
                                Thermal Club is an exclusive track that offers three unique
                                layouts, plus our own challenge course to encourage maximum
                                thrills.
                            </p>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default ThermalCaliforniaBanner;
