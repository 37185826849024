import React, { Component } from 'react';
import * as Yup from 'yup';
import AXIOS from '../../../axios-config';
import cogoToast from 'cogo-toast';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../../settings';
import MonthSlider from '../MonthSlider';


const comingsoon = `${settings.IMAGES_BASE_URL}/images/m4-gt4/bmw-m4-gt4-booking.jpg`;

export default class ComingSoon extends Component {
  state = {
    isLoading: false
  };

  initialValues = {
    firstname: '',
    lastname: '',
    email: ''
  };

  validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    email: Yup.string()
      .email()
      .required('Please enter your email address')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Your email address is incorrect')
  });

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({
      isLoading: true
    });
    setSubmitting(true);

    AXIOS.post('/api/m4gt4-form', values)
      .then(() => {
        this.setState({
          isLoading: false
        });
        resetForm();
        setSubmitting(false);
        cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        setSubmitting(false);
        cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
      });
  };

  render() {
    return (
      <div className="comingsoon">
        <div className="container">
          <div className="comingsoon__class">
            <div className="header">
              <h2>Class options</h2>
              <p>The M4 GT4 is a specialized race car with heavily bolstered bucket seats that can accommodate up to a size 46-inch waist. We cannot guarantee fitment. Please call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> if you have any questions regarding the BMW M4 GT4 driving experience.</p>
            </div>
            <div className="row first-class">
              <div className="col">
                <div className="comingsoon__class__item">
                  <div className="comingsoon__class__card">
                    <div className="comingsoon__class__card__header">
                      <h3>BMW M and M4 GT4 Experience</h3>
                      <h4 className="price">$2,795</h4>
                    </div>
                    <div className="comingsoon__class__card__content">
                      <p>
                        A full-day program includes driving in standard BMW M cars and one 30-45 minute driving rotation in a M4 GT4 race car, including the gear up time. You’ll start
                        with a classroom session that reviews proper driving technique and get fitted out for your GT4.
                        In addition, the class includes:
                      </p>
                      <ul>
                        <li>VBOX data and video system in the M4 GT4 to record your session. </li>
                        <li>Access to professional instructors, trackside crew and support.</li>
                        <li>
                          All driving gear provided for the duration of the class – racing suit, helmet, gloves, shoes.
                        </li>
                        <li>Lunch at our café.</li>
                        <li>Insurance, with a $2,500 deductible for vehicle or property damage.</li>
                      </ul>

                      <p>
                        Discounts are not allowed for this program. There is a 15 person max in the class and one person per
                        vehicle. The BMW M and M4 GT4 Experience does not run during the Summer season.
                      </p>
                      <MonthSlider
                        monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
                        backgroundColor="rgb(203 203 203)"
                        brand="M"
                        urlQuery="&class=M4_GT4_Experience"
                        showSpecificMonths={true}
                        monthsToShow={["01","02","03","04","05","10","11","12"]}
                        month="10"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <img src={comingsoon} alt="BMW M and M4 GT4 Experience" />
              </div>
            </div>
            <div className="row second-class">
            <div className="col">
                <div className="comingsoon__class__item">
                  <div className="comingsoon__class__card">
                    <div className="comingsoon__class__card__header">
                      <h4>
                        HALF-DAY OF M4 GT4 PRIVATE COACHING WITH <br /> DATA & VIDEO
                      </h4>
                      <h4 className="price">$4,995</h4>
                    </div>
                    <div className="comingsoon__class__card__content">
                      <p>
                      A half-day program includes up to 125 miles of track driving in the M4 GT4 race
                      car. You'll start with a classroom session that reviews proper driving technique
                      and get fitted out for your GT4. In addition, the class includes:
                      </p>
                      <ul>
                        <li>VBOX data and video system in the M4 GT4 to record your session.</li>
                        <li>Time in the BMW M4 GT4 race car, one professional instructor and trackside support.</li>
                        <li>
                          All driving gear provided for the duration of the class – racing suit, helmet, gloves, shoes.
                        </li>
                        <li>Lunch at our café.</li>
                        <li>Insurance, with a $2,500 deductible for vehicle or property damage.</li>
                      </ul>

                      <p>
                        More miles can be purchased on the day of training. The program is designed for one driver.
                        However, two drivers sharing a car can be arranged upon request.
                      </p>
                      <br />
                      <p>
                        The schedule is flexible. Please call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a>, and one of our driving instructors will
                        reply to answer any questions and schedule your coaching session.
                      </p>
                      <br />
                      <p>
                       Discounts are not allowed for this program.
                      </p>
                      <div className="comingsoon__class__card__links">
                        <a href="tel:888-345-4269">Call to Book</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="comingsoon__class__item">
                  <div className="comingsoon__class__card">
                    <div className="comingsoon__class__card__header">
                      <h4>
                        FULL-DAY OF M4 GT4 PRIVATE COACHING WITH  <br />  DATA & VIDEO
                      </h4>
                      <h4 className="price">$9,995</h4>
                    </div>
                    <div className="comingsoon__class__card__content">
                      <p>
                        A full-day program includes up to 250 miles (or 8 hours) of track driving in the M4 GT4 race car. You’ll start with
                        a classroom session that reviews proper driving technique and get fitted out for your GT4. In
                        addition, the class includes:
                      </p>

                      <ul>
                        <li>VBOX data and video system in the M4 GT4 to record your session.</li>
                        <li>Time in the BMW M4 GT4 race car, one professional instructor and trackside support.</li>
                        <li>
                          All driving gear provided for the duration of the class – racing suit, helmet, gloves, shoes.
                        </li>
                        <li>Lunch at our café.</li>
                        <li>Insurance, with a $2,500 deductible for vehicle or property damage.</li>
                      </ul>

                      <p>
                        More miles can be purchased on the day of training. The program is designed for one driver.
                        However, two drivers sharing a car can be arranged upon request.{' '}
                      </p>
                      <br />
                      <p>
                        The schedule is flexible. Please call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a>, and one of our driving instructors will
                        reply to answer any questions and schedule your coaching session.
                      </p>
                      <br />
                      <p>
                       Discounts are not allowed for this program.
                      </p>
                      <div className="comingsoon__class__card__links">
                        <a href="tel:888-345-4269">Call to Book</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
